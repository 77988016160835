<template>
  <ion-page>
    <ion-header class="headerTop ion-no-border">
      <headertoolbar></headertoolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <div class="original_main_container">
        <ion-header collapse="condense">
        </ion-header>
        <ion-icon class="l_close_a" button @click="this.$router.push('/tabs/tabReproductor')"
          src="assets/ICONOS/close.svg"></ion-icon>
        <p class="tabTitle">Audios a demanda</p>
        <ion-text class="title_desc" v-if="hayAudios == 1">
          <p>Seleccione un audio a demanda para reproducirlo a continuación en tu canal, la canción o spot actual se
            pausará y continuará en automatico al término de la reproducción de este audio a demanda.</p>
        </ion-text>
        <ion-text class="title_desc" v-else-if="hayAudios == 2">
          <h5>Sin audios a demanda disponibles...</h5>
          <p>Por el momento usted no cuenta con audios a demanda disponibles,
            si necesita agregar audios, favor de ponerse en contacto
            con alguno de nuestros asesores.
          </p>
          <footerContactanos></footerContactanos>
        </ion-text>
        <div class="out_loader" v-if="hayAudios == 0">
          <div class="loader"></div>
        </div>
        <ion-list lines="inset" class="link_items">

          <ion-item class="link_item" v-for="audio in audiosReq" :key="audio.id">
            <ion-grid class="left_div_link">
              <ion-row class="play_button_link">
                <ion-col class="div">
                  <ion-icon class="play" v-on:click="confirmarAudioDemanda(audio)"
                    src="assets/ICONOS/reproductor-blanco.svg"></ion-icon>
                  <ion-icon class="pause" src="assets/ICONOS/i-pausa.svg"></ion-icon>
                </ion-col>
              </ion-row>
              <ion-row class="text_p">
                <ion-col class="div">
                  <h3 class="text">{{ audio.name }}</h3>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-item>

        </ion-list>

      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="js">
/* eslint-disable */
import { IonPage, IonHeader, IonToolbar, IonContent, alertController } from '@ionic/vue';
import axios from 'axios';
import { defineComponent } from 'vue';
import footerContactanos from './footerContactanos.vue';
import { Storage } from '@capacitor/storage';
import headertoolbar from './header_toolbar.vue';
export default defineComponent({
  name: 'Reproductor',
  components: { IonHeader, IonToolbar, IonContent, IonPage, footerContactanos, headertoolbar },
  data() {
    return {
      audios: [{
        id: 1,
        name: "Lista de Éxitos",
        imagePath: "lista-de-exitos.png"
      }],
      audiosReq: [],
      ddata: [],
      hayAudios: 0,
      clientData: {},

    }
  },
  methods: {
    async loadClient() {
      this.audiosReq = this.ddata.oaudios;
      this.audiosReq.length > 0 ? this.hayAudios = 1 : this.hayAudios = 2;
      console.log(this.audiosReq);
      /* })
       .catch(error => {
         console.error("There was an error!", error);
       });*/
    },
    async confirmarAudioDemanda(audio) {
      try {
        const alert = await alertController
          .create({
            header: "Aviso",
            message: "¿Deseas reproducir este audio? se pausará la cancion actual.",
            buttons: [
              {
                text: 'Si',
                handler: () => {
                  console.log('Reproducir audio a demanda pulsado, audio: ' + JSON.stringify(audio));
                  // this.cambiarCategoria(idCategoria).then
                  // this.getClientData("categoriaSeleccionada");
                  this.reproducirAudio(audio);
                }
              },
              {
                text: 'Cancelar',
                handler: () => {
                  console.log('Cancelar pulsado');
                }
              },
            ]
          });
        return alert.present();
      } catch (e) {
        this.presentAlertConfirm("Ha ocurrido un error en la función confirmarCambioCategoria", e);
      }
    },
    async reproducirAudio(jAudio) {
      try {
        console.log(jAudio);
        Storage.remove({ key: 'audioDemanda' });
        Storage.set({
          key: "audioDemanda",
          value: JSON.stringify(jAudio),
        })
          .then
        console.log("Asignado a storage exitosamente - " + jAudio);
        this.getClientData("audioDemanda");
        this.$router.replace("/tabs/tabReproductor");
      } catch (e) {
        this.presentAlertConfirm("Ha ocurrido un error en la función reproducirAudio", e);
      }
    },
    async getClientData(keyName) {
      try {
        const ret = await Storage.get({ key: keyName });
        if (ret.value) {
          this.audioDemanda = JSON.parse(ret.value);
          console.log("Recuperado de storage, key:" + keyName + " - " + ret.value);
          console.log(JSON.parse(ret.value));
        } else {
          console.log("No se pudo recuperar de storage, key:" + keyName);

        }
      } catch (e) {
        this.presentAlertConfirm("Ha ocurrido un error en la función getClientData", e);
      }
    },
    async presentAlertConfirm(headerAlert, messageAlert) {
      const alert = await alertController
        .create({
          header: headerAlert,
          message: messageAlert,
          buttons: ['Ok'],
        });
      return alert.present();
    },
    async getFullClientData() {
      try {
        const ret = await Storage.get({ key: "clientData" });
        if (ret.value) {
          this.clientData = JSON.parse(ret.value);
          console.log("Recuperado de storage, key: clientData - " + this.clientData);

          if (this.clientData.sonara) {
            this.emitter.emit("sonara", true);
          } else {
            this.emitter.emit("sonara", false);
          }
        } else {
          console.log("No se pudo recuperar de storage, key: clientData - " + this.clientData);
        }
      } catch (e) {
        this.presentAlertConfirm("Ha ocurrido un error en la función getFullClientData", e);
      }
    },
  },
  mounted: function () {
    this.emitter.on("g-data", ddata => { this.ddata = ddata; this.loadClient(); });
    this.getFullClientData();
  },
  computed: {
    getImgUrl(pic) {
      // return require('~@/assets/audios/'+pic);
      return (pic);
    }
  }
})
</script>


<style scoped>
* {
  font-family: 'Montserrat', sans-serif;
}

.headerTop {
  background-color: black;
}

.play_button_link {
  float: left;
  font-size: 30px;
  line-height: 30px;
  margin: 0px 12px 0px 0px;
}

.link_items {
  padding: 10px;
  background-color: black;
}

.link_items .selected {
  --background: linear-gradient(to right, #04949c, #00a8ad);
}

.link_item {
  padding: 5px 0;
  background-color: transparent;
  max-width: 450px;
  margin: auto;
  --border-radius: 10px;
}

.link_item h2 {
  font-weight: bold;
}

.link_items .play {
  display: block;
}

.link_items .pause {
  display: none;
}

.link_items .selected .play {
  display: none;
}

.link_items .selected .pause {
  display: block;
}

.link_items .play,
.link_items .pause {
  margin: 6px auto !important;
  font-size: 36px;
  line-height: 36px;
}

.link_item .text_p .text {
  margin: 6px 0px;
  font-size: 20px;
  line-height: 36px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

.left_div_link {
  width: 100%;
}

/* LOADER ----------------------------------*/
.out_loader {
  width: 60px;
  margin: 100px auto;
}

.loader {
  border: 10px solid #3c3c3c;
  border-radius: 50%;
  border-top: 10px solid #c3c3c3;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* LOADER ----------------------------------*/

@media only screen and (min-width: 850px) {
  .link_item {
    width: calc(33.33% - 10px);
    float: left;
    margin: 5px;
  }

  .link_items .play,
  .link_items .pause {
    margin: 6px auto !important;
    font-size: 60px;
    line-height: 60px;
  }

  .play_button_link {
    float: none;
    margin: 0px;
  }

  .link_item .text_p .text {
    text-align: center;
  }

}

@media only screen and (min-width: 980px) {
  .link_item {
    width: calc(25% - 10px);
  }

  .link_item .text_p .text {
    font-size: 18px;
  }
}

@media only screen and (max-width: 380px) {
  .link_item .text_p .text {
    font-size: 16px;
  }
}
</style>